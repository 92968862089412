import Gmodal from 'glory-modal';

export default () => {


    const newsletterKey = "newsletter-seen";
    let newsletterDisabled = window.newsletterDisabled;
    let newsletterSeen = window.localStorage.getItem(newsletterKey);
    // window.localStorage.removeItem(newsletterKey); // @debug 

    let newsletterInvitation = document.getElementById('newsletter-invitation');
    let dismissNewsletterInvitation = document.getElementById('dismiss-newsletter-invitation');
    let showNewsletterFormBtn = document.getElementById('show-newsletter-form');
    let showNewsletterFormFooterBtn = document.getElementById('show-newsletter-form-footer');
    let newsletterModalEl = document.getElementById('newsletterModal');

    const newsletterModal = new Gmodal(newsletterModalEl, {
        stickySelectors: [],
        animation: true,
        backdrop: true,
        closeBackdrop: true,
        keyboard: true
    });

    showNewsletterFormFooterBtn.addEventListener('click', function(event) {
        window.localStorage.setItem(newsletterKey, "true");
        newsletterModal.open();
        event.preventDefault();
    });

    // Show the hubspot form and set the newsletterKey local storage value to true.
    newsletterModalEl.addEventListener('gmodal:beforeopen', function(evt) {

        hbspt.forms.create({
            region: "na1",
            target: '#newsletterForm',
            portalId: "5257953",
            formId: "c556d07d-a4ef-4cdd-91a5-09d23e76cce1"            
        });
        window.localStorage.setItem(newsletterKey, "true");
    });


    if (newsletterSeen !== "true") {

        if (newsletterDisabled === true) {
            return;
        }

        // Dismiss the newsletter invitation and set the newsletterKey local storage value to true.
        dismissNewsletterInvitation.addEventListener('click', function(event) {
            window.localStorage.setItem(newsletterKey, "true");
            newsletterInvitation.classList.add('fade-out-up');
            event.preventDefault();
        });

        // Show the newsletter form
        showNewsletterFormBtn.addEventListener('click', function(event) {
            newsletterInvitation.classList.add('fade-out-up');
            newsletterModal.open();
            event.preventDefault();
        });
    
        
        scrollDistance(function (distance) {

            // After user has scrolled at least 500px
            if (distance >= 500) {
                newsletterInvitation.classList.remove('hidden');
                newsletterInvitation.classList.add('fadeindown');
            }
            // console.log('Scrolled ' + parseInt(Math.abs(distance), 10) + 'px ' + (distance < 0 ? 'up' : 'down'));  // @debug
            
        });

    }




    function scrollDistance (callback, refresh = 66) {

        // Make sure a valid callback was provided
        if (!callback || typeof callback !== 'function') return;

        // Variables
        let isScrolling, start, end, distance;

        // Listen for scroll events
        window.addEventListener('scroll', function (event) {

            // Set starting position
            if (!start) {
                start = window.pageYOffset;
            }

            // Clear our timeout throughout the scroll
            window.clearTimeout(isScrolling);

            // Set a timeout to run after scrolling ends
            isScrolling = setTimeout(function() {

                // Calculate distance
                end = window.pageYOffset;
                distance = end - start;

                // Run the callback
                callback(distance, start, end);

                // Reset calculations
                start = null;
                end = null;
                distance = null;

            }, refresh);

        }, false);

    }


}