import Gmodal from 'glory-modal';

export default () => {

    let quickViewLinks = document.querySelectorAll("a.inline-modal");

    if (!quickViewLinks.length) {
        return;
    }

    quickViewLinks.forEach(function (qvlink, index) {
        qvlink.addEventListener('click', function (event) {
            event.preventDefault();
            quickView(qvlink);
        });
    });

    function quickView(qvlink) {

        let selector = '#modal';

        let modalId = qvlink.getAttribute('data-modal-id');
        
        if (modalId) {
            selector = document.getElementById(modalId);
        }

        let config = {
            stickySelectors: [],
            animation: false,
            backdrop: true,
            closeBackdrop: true,
            keyboard: true
        }

        let modal = new Gmodal(selector, config);
        modal.open();
    }

}