import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse'
import intersect from '@alpinejs/intersect';
import focus from '@alpinejs/focus';
import persist from '@alpinejs/persist';

import tippy from "./parts/tippy";
import discontinued from "./parts/discontinued";
import gallery from "./parts/gallery";
import smoothscroll from "./parts/smoothscroll";
import swiper from "./parts/swiper";

// 1st party plugins
Alpine.plugin(intersect);
Alpine.plugin(collapse);
Alpine.plugin(focus);
Alpine.plugin(persist);

// Components
Alpine.data("discontinued", discontinued);
Alpine.data("tippy", tippy);
Alpine.data("gallery", gallery);
Alpine.data("smoothScroll", smoothscroll);
Alpine.data("swiper", swiper);

window.Alpine = Alpine;
Alpine.start();
