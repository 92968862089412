/**
 * Usage:
 *
 * Step 1: Somewhere in JS...
 *   import Alpine from "alpinejs";
 *   import headroom from "./gallery";
 *   Alpine.data('gallery', gallery);
 *   Alpine.start();
 *
 * Step 2: Somewhere in markup...
 *   <div x-data="gallery()" />
 *
 * @param selector
 * @returns {{init(): void, el: null, selector: null}}
 */

import Glightbox from 'glightbox';

export default () => ({

    lightbox: null,

    init() {

        this.$nextTick(() => {

            /*
                Regardless, we need to initialize the gallery to begin
             */
            this.setupLightbox();

            /* The htmx variable will only be found on pages where
            *
            *   {% block scriptAdditions %}
                  {{ sprig.script }}
                {% endblock %}
            *
            * has been included. Otherwise, exit this function.
            * */

            if (typeof htmx === 'undefined') {
                return;
            }

            htmx.on('htmx:afterOnLoad', (evt) => {
                /* On a sprig event, we want to reinitialize the lightbox
                * including any new additional assets */
                this.setupLightbox();
            });
        });
    },

    setupLightbox() {
        this.lightbox = Glightbox();
    }
});