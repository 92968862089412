import React from 'react';
import { createRoot } from 'react-dom/client';
import algoliasearch from 'algoliasearch/lite';
import {
    useInstantSearch,
    InstantSearch,
    Configure,
    Index,
    Hits,
    SearchBox,
    Highlight
  } from 'react-instantsearch';


const searchRoot = document.getElementById('searchapp');
const ALGOLIA_APPLICATION_ID = import.meta.env.VITE_ALGOLIA_APPLICATION_ID;
const ALGOLIA_SEARCH_API_KEY = import.meta.env.VITE_ALGOLIA_SEARCH_API_KEY;
const searchClient = algoliasearch(ALGOLIA_APPLICATION_ID, ALGOLIA_SEARCH_API_KEY);

export default () => {
    if (!searchRoot) {
        return false;
    }
    const root = createRoot(searchRoot);
    root.render(<RootIndex/>);
}

// Display the No Results message when no hits have been returned.
function NoResultsBoundary({ children, fallback }) {
    const { results } = useInstantSearch();
    if (!results.__isArtificial && results.nbHits === 0) {
        return (
            <>
            {fallback}
            <div hidden>{children}</div>
            </>
        );
    }
    return children;
}
  
function NoResults() {
    const { indexUiState } = useInstantSearch();
    return (
      <div className="text-center my-12 mx-auto text-lg text-brand-blue-900 flex flex-col gap-4 justify-center items-center max-w-sm">
        <div className="bg-white rounded-full w-16 h-16 flex items-center justify-center">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.6654 3.026V8.53339C18.6654 9.28012 18.6654 9.65349 18.8107 9.93871C18.9385 10.1896 19.1425 10.3936 19.3934 10.5214C19.6786 10.6667 20.052 10.6667 20.7987 10.6667H26.3061M12.6654 16L19.332 22.6666M19.332 16L12.6654 22.6666M18.6654 2.66663H11.732C9.49182 2.66663 8.37172 2.66663 7.51607 3.1026C6.76342 3.48609 6.1515 4.09802 5.76801 4.85066C5.33203 5.70631 5.33203 6.82642 5.33203 9.06663V22.9333C5.33203 25.1735 5.33203 26.2936 5.76801 27.1493C6.1515 27.9019 6.76342 28.5138 7.51607 28.8973C8.37172 29.3333 9.49182 29.3333 11.732 29.3333H20.2654C22.5056 29.3333 23.6257 29.3333 24.4813 28.8973C25.234 28.5138 25.8459 27.9019 26.2294 27.1493C26.6654 26.2936 26.6654 25.1735 26.6654 22.9333V10.6666L18.6654 2.66663Z" stroke="#2678AB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/></svg>
        </div>
        <p>Sorry, no results were found for your search. Please try again..</p>
      </div>
    );
}



function CardProductEntry(props) {
    const product = props?.hit;
    return (
        <a key={product.id} href={'/products' + product.slug} className="product-card bg-white p-4 border hover:border-brand-blue-200 hover:text-brand-blue-500 transition-all duration-300 cursor-pointer rounded-md flex items-center gap-2 md:gap-4">

            {props.hit.img ?
                <div className="w-24 h-24 sm:w-32 sm:h-32 aspect-square bg-contain bg-center bg-no-repeat" style={{
                    backgroundImage: `url(${product.img})`
                  }}>
                </div>
            :
                <div></div>
            }
            <div className="flex flex-col">
                <h3 className="font-medium text-base md:text-lg xl:text-xl mb-4 text-brand-blue-900">{product.shortDescription}</h3>
                <h5 className="text-xs uppercase text-brand-blue-300 font-semibold mb-3"><Highlight attribute="title" classNames={{highlighted: 'bg-yellow-100'}} hit={product} /></h5>
                <div className="bg-brand-blue-50 text-brand-blue-300 rounded-3xl py-1 px-3 inline-flex items-center text-xs mr-auto">
                    {product.styles[0]}
                </div>
            </div>

        </a>
    )
}


function ProductList(props) {
    const skus = props.resource.product_skus;
    return (<div className='text-xs text-brand-blue-200 uppercase mt-2'>{skus.map(function(item, index) {return <span key={index}>{ (index ? ', ' : '') + item }</span>})}</div>);
}


function CardResourceEntry(props) {
    const resource = props?.hit;
    return (
        <a key={resource.id} href={'/resources/view/' + resource.id} className="resource-card bg-white p-4 border hover:border-brand-blue-200 hover:text-brand-blue-500 transition-all duration-300 cursor-pointer flex justify-between rounded-md">
            <div className="flex flex-col">
                <h3 className="font-medium text-base md:text-lg xl:text-xl/5 mb-4 text-brand-blue-900"><Highlight attribute="title" classNames={{highlighted: 'bg-yellow-100'}} hit={resource} /></h3>
                <div className="text-xs uppercase text-brand-blue-300 font-semibold">
                    <ProductList resource={resource}/>
                </div>
            </div>
            <div className="shrink-0">
                <div className="bg-brand-blue-50 text-brand-blue-300 rounded-3xl py-1 px-3 inline-flex items-center text-xs mr-auto">
                        {resource.type}
                </div>
            </div>
        </a>
    )
}

function RootIndex() {
// export default () => {

    return (
        <InstantSearch searchClient={searchClient} indexName="products">
        <div className="mx-auto h-full min-h-screen overflow-auto relative">
            <div className="flex items-center border-b border-brand-neutral-400 sticky top-0 bg-white lg:bg-brand-neutral-200 pb-6 pt-16">
                <button x-on:click="searchMenuOpen=false" className="absolute right-4 top-4">
                    <svg className="text-brand-neutral-500 w-8 h-auto" xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" viewBox="0 0 256 256"><path d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"></path></svg>
                </button>
                <SearchBox placeholder="Search products"
                    classNames={{
                        root: 'w-full mx-4',
                        form: 'relative',
                        input: 'w-full border border-brand-neutral-400 outline-brand-blue-100 rounded-md p-4 pl-12',
                        submit: 'absolute left-4 top-4',
                        reset: 'absolute right-4 top-4'
                    }}
                    submitIconComponent={() => (
                        <svg className="w-6 h-auto fill-current text-slate-400" xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="#000000" viewBox="0 0 256 256"><path d="M229.66,218.34l-50.07-50.06a88.11,88.11,0,1,0-11.31,11.31l50.06,50.07a8,8,0,0,0,11.32-11.32ZM40,112a72,72,0,1,1,72,72A72.08,72.08,0,0,1,40,112Z"></path></svg>
                    )}
                    resetIconComponent={({ classNames }) => (
                        <svg className="w-6 h-auto fill-current text-slate-600" xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="#000000" viewBox="0 0 256 256"><path d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"></path></svg>
                    )}                                    
                />
            </div>
            <Index indexName="products">
                <Configure hitsPerPage={4} />
                <div className="py-6 px-4">
                    <h4 className="text-brand-neutral-700 text-sm pb-3">Products</h4>
                    <NoResultsBoundary fallback={<NoResults />}>
                        <Hits
                            hitComponent={CardProductEntry}
                            classNames={{
                                list: 'grid gap-3 md:gap-4',
                            }}
                        />
                    </NoResultsBoundary>
                </div>
            </Index>
            <Index indexName="product_resources">
                <Configure hitsPerPage={4} />
                <div className="py-6 px-4">
                    <h4 className="text-brand-neutral-700 text-sm pb-3">Resources</h4>
                    <NoResultsBoundary fallback={<NoResults />}>
                        <Hits
                            hitComponent={CardResourceEntry}
                            classNames={{
                                list: 'grid gap-3 md:gap-4',
                            }}
                        />
                    </NoResultsBoundary>
                </div>
            </Index>
        </div>
    </InstantSearch>
    );


}