/**
 * Usage:
 *
 * Step 1: Somewhere in JS...
 *   import Alpine from "alpinejs";
 *   import spine from "./smoothScroll";
 *   Alpine.data('smoothScroll', smoothScroll);
 *   Alpine.start();
 *
 * Step 2: Somewhere in markup...
 *   <div x-data="smoothScroll('selector')" />
 *
 * @param selector
 * @returns {{init(): void, selector: null}}
 */
export default (selector = null) => ({
    target: null,
    selector: selector,

    init() {
        if (!this.selector) {
            return;
        }
        this.target = document.querySelector(this.selector);

        if (!this.target) {
            return;
        }
        this.$el.addEventListener('click', () => {
            this.target.scrollIntoView({
                behavior: "smooth"
            });
        });
    }
});
