import Swiper, { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';

export default () => {

    const productSlides = document.querySelectorAll('[data-standard-swiper] .swiper-slide');

    if (!productSlides.length) {
        return;
    }

    const slides = [];

    const singleProductSwiperOpts = {
        modules: [Navigation],
        grabCursor: true,
        simulateTouch:true,
        loop: false,
        speed: 600,
        centeredSlides: false,
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 0,
        effect: 'fade',
        pagination: {
            el: '.swiper-pagination',
            type: 'progressbar',
        },
        fadeEffect: {
            crossFade: true
        },
        slideToClickedSlide: true,
        autoHeight: false,
        navigation: {
            nextEl: '[data-standard-swiper] .next-slide',
            prevEl: '[data-standard-swiper] .prev-slide',
            disabledClass: 'opacity-50'
        }
    };

    const singleProductSwiper = new Swiper('[data-standard-swiper] .swiper', singleProductSwiperOpts);

}