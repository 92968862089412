/**
 * Usage:
 *
 * Step 1: Somewhere in JS...
 *   import Alpine from "alpinejs";
 *   import headroom from "./tippy";
 *   Alpine.data('tippy', tippy);
 *   Alpine.start();
 *
 * Step 2: Somewhere in markup...
 *   <div x-data="tippy('{{stringVariable}}')" />
 *
 * @param selector
 * @returns {{init(): void, el: null, selector: null}}
 */

import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css'; // optional for styling


export default (tooltipContents = '', position = '') => ({

    tooltipContents: '<div class="text-brand-blue-900">For discontinued KCL or Revit files, please contact <a class="underline text-brand-blue-500" href="mailto:productquestions@structuralconcepts.com">product support</a>.</div>',
    tooltipTarget: null,

    init() {
        let instance = tippy(this.$el, {
            content: this.tooltipContents,
            allowHTML: true,
            interactive: true,
            maxWidth: 300,
            theme: 'scc',
            placement: position ?? 'auto'

        });
    }
});