/**
 * Usage:
 *
 * Step 1: Somewhere in JS...
 *   import Alpine from "alpinejs";
 *   import spine from "./swiper";
 *   Alpine.data('swiper', swiper);
 *   Alpine.start();
 *
 * Step 2: Somewhere in markup...
 *   <div x-data="...swiper({
            slidesPerView:1,
            ...etc
            })}" />
 *
 * @returns {{init(): void, el: null, selector: null}}
 */

// import any additional classes such as { Pagination }
import Swiper, {EffectFade, Pagination} from 'swiper';
import 'swiper/swiper-bundle.min.css';

// initialize any additional classes such as { Pagination }
Swiper.use([EffectFade, Pagination]);

export default (opts = {}) => ({
    options: {
        ...opts
    },
    swiper: null,


    init() {

        if (this.swiper !== null) {
            return;
        }

        let el = this.$refs.swiper ?? this.$el;
        this.swiper = new Swiper(el, this.options);

        if (this.swiper.el) {


            if (this.swiper.params.init === false && this.options.equalize === true) {

                this.swiper.on('afterInit', (swiper) => {
                    this.equalize(swiper);
                });
                this.swiper.on('resize', (swiper) => {
                    this.equalize(swiper);
                });
                this.swiper.on('slideChange', (swiper) => {
                    this.equalize(swiper);
                });
                this.swiper.init();

            }

            this.swiper.on('slideChange', () => {
                this.buttonStates();
            });
        }
    },

    prev() {
        if (this.swiper) {
            this.swiper.slidePrev();
        }
    },

    next() {
        if (this.swiper) {
            this.swiper.slideNext();
        }
    },

    equalize(swiper) {

        if (swiper.initialized === true) {

            let slides = swiper.slides;
            let max = 0;
            let equalizers = [];

            slides.forEach((slide) => {
                let equalizer = slide.querySelector('.equalizer');
                let equalized = slide.querySelector('.equalize');
                if (equalizer) {
                    max = Math.max(max, equalizer.offsetHeight);
                    equalizers.push(equalized);
                } else if(equalized) {
                    max = Math.max(max, equalized.offsetHeight);
                    equalizers.push(equalized);
                }
            });

            if (equalizers.length) {
                equalizers.forEach((equalizer) => {
                    equalizer.style.height = max + 'px';
                });
            }


        }
    },

    buttonStates() {
        if (this.swiper) {
            if (this.$refs.prev && this.$refs.next) {
                this.$refs.prev.disabled = this.swiper.isBeginning;
                this.$refs.next.disabled = this.swiper.isEnd;
            }
        }
    }

});
