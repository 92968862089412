/**
 * Usage:
 *
 * Step 1: Somewhere in JS...
 *   import Alpine from "alpinejs";
 *   import headroom from "./tippy";
 *   Alpine.data('tippy', tippy);
 *   Alpine.start();
 *
 * Step 2: Somewhere in markup...
 *   <div x-data="tippy('{{stringVariable}}')" />
 *
 * @param selector
 * @returns {{init(): void, el: null, selector: null}}
 */

import tippy, {sticky} from 'tippy.js';
import 'tippy.js/dist/tippy.css'; // optional for styling

export default (tooltipContents = '', position = '') => ({

    tooltipContents: tooltipContents,
    tooltipTarget: null,

    init() {
        let instance = tippy(this.$el, {
            content: this.tooltipContents,
            allowHTML: true,
            maxWidth: 450,
            sticky: true,
            plugins: [sticky],
            theme: 'scc',
            placement: 'auto'

        });
    }
});