// Required for dev hot reload

if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR")
  });
}

import searchDrawer from './components/search-drawer';
document.addEventListener('DOMContentLoaded', searchDrawer, false);

import initMegaMenu from './modules/megaMenu';
document.addEventListener('DOMContentLoaded', initMegaMenu, false);

import initstandardSwiper from './modules/standardSwiper';
document.addEventListener('DOMContentLoaded', initstandardSwiper, false);

import initInlineModal from './modules/inlineModal';
document.addEventListener('DOMContentLoaded', initInlineModal, false);

import initscrollDrag from './modules/scrollDrag';
document.addEventListener('DOMContentLoaded', initscrollDrag, false);

import initNewsletterSignup from './modules/newsletter';
document.addEventListener('DOMContentLoaded', initNewsletterSignup, false);

import initoffCanvasSwiper from './modules/offCanvasSwiper';
document.addEventListener('DOMContentLoaded', initoffCanvasSwiper, false);

import '../css/app.css';
import 'virtual:svg-icons-register';

// Import and initialize alpine js
import './alpine/index';