import Swiper, {Navigation} from 'swiper';
Swiper.use([Navigation]);

export default () => {

    const swipers = document.querySelectorAll('.pdf-carousel');
    if (!swipers.length) {
        return;
    }

    swipers.forEach((swiperEl,index) => {

        const allSlides = swiperEl.querySelectorAll('.swiper-slide');

        if (!allSlides.length) {
            return;
        }

        const productSwiperOpts = {
            grabCursor: true,
            simulateTouch:true,
            loop: false,
            speed: 600,
            centeredSlides: false,
            centerInsufficientSlides: false,
            slidesPerView: 1.5,
            slidesPerGroup: 1,
            spaceBetween: 24,
            slideToClickedSlide: false,
            watchSlidesProgress: true,
            autoHeight: false,
            init: false,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                768: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                },
                1024: {
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                }
            }
        };

        const swiper = swiperEl.querySelector('.swiper');


        if (swiper) {
            const productSwiper = new Swiper(swiper, productSwiperOpts);
            productSwiper.init();
        }

    });

}